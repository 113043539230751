import { createGlobalStyle } from 'styled-components'
import { VisuallyHiddenStyles } from './components/_styled/BootStrapGrid'

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: 400;
  }
  
  *, ::after, ::before {
    box-sizing: border-box;
  }
  
  @font-face {
    font-family:'Lato';
    src: url('https://fonts.googleapis.com/css?family=Lato');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'RobotoFlex';
    src: url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800&display=swap');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Futura PT';
    src: local('Futura PT'), url('/fonts/ftn45.woff2') format('woff2'), url('/fonts/ftn45.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Futura PT Bold';
    src: local('Futura PT Bold'), url('/fonts/ftn65.woff2') format('woff2'), url('/fonts/ftn65.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  body {
    font-family: 'Futura PT', Arial Narrow, Arial, Helvetica, sans-serif ;
    margin: 0;
    font-size: 1.7rem;
    line-height: 1.3;
    &.bodyMarginTopNone {
        margin-top: 0 !important;
    }
  }
  @-moz-document url-prefix() {
    body {
      font-weight: lighter !important;
    }
    h1,h2,h3,h4,h5,h6 {
      font-weight: lighter !important;
    }
    p {
      font-weight: lighter !important;
    }
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    letter-spacing: .1rem;
    font-family: 'Futura PT', Trebuchet MS, Arial, Helvetica, sans-serif;
  }
  
  p {
    font-family: 'Futura PT', Trebuchet MS, Arial, Helvetica, sans-serif;
  }
  // for chat
  .visually-hidden{
    ${VisuallyHiddenStyles};
  }
 
  button{
    font-family: inherit;
  }
  
  @media print {
      #talkable-offer, .ie-div-position-customer-chat {
          display: none !important;
      }
  }
  
  @media (max-width: 1279px) {
      #checkoutContainer #mainContent, #mainContent.pdpTabView {
          margin-top: 0;
      }
      .ie-chat-button-iframe-fixed-customer-chat {
          top: 56% !important;
      }
      .ie-div-position-customer-chat .button-iframe-wrap {
          z-index: 99 !important;
      }
      #attentive_creative {
          bottom: 100px !important;
      }
  }
  
  @media (min-width: 1280px) { 
    .ie-chat-button-iframe-fixed-customer-chat {
        right: 4px !important;
        bottom: 2% !important;
    }
  }
//  Chat CSS -- would not need all of this if chat button was set to right -- percentage is relative to the css set to left
  @media (min-width: 1120px) and (max-width: 1279px) {
      .ie-chat-button-iframe-fixed-customer-chat {
          left: 96.1% !important;
      }
  }
  @media (min-width: 1080px) and (max-width: 1119px) {
      .ie-chat-button-iframe-fixed-customer-chat {
          left: 95.8% !important;
      }
  }
  @media (min-width: 980px) and (max-width: 1079px) {
      .ie-chat-button-iframe-fixed-customer-chat {
          left: 95.4% !important;
      }
  }
  @media (min-width: 88px) and (max-width: 979px) {
      .ie-chat-button-iframe-fixed-customer-chat {
          left: 95% !important;
      }
  }
  @media (min-width: 780px) and (max-width: 879px) {
      .ie-chat-button-iframe-fixed-customer-chat {
          left: 94.4% !important;
      }
  }
  @media (min-width: 750px) and (max-width: 779px) {
      .ie-chat-button-iframe-fixed-customer-chat {
          left: 93.7% !important;
      }
  }
  @media (max-width: 749px) {
      .ie-chat-button-iframe-fixed-customer-chat {
          left: 93% !important;
      }
  }

  .futura-pt {
    font-family: 'Futura PT', sans-serif;
  }
  .futura-pt-bold {
    font-family: 'Futura PT Bold', sans-serif;
  }
  
  #rokt-above-payment iframe, #rokt-below-payment iframe {
      box-sizing: initial !important;
  }
`